<template>
  <div class="mb-0 d-flex justify-content-between align-items-center">
    <div class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT © {{ new Date().getFullYear() }}
      <b-link class="ml-25" href="https://www.aliff.in/" target="_blank"
        >Aliff</b-link
      >
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </div>

    <!-- <span class="float-md-right d-none d-md-block">Hand-crafted &amp; Made with
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span> -->
    <!-- <b-row class="gap-2">
      <b-col class="">
        <b-link :to="{ name: 'Terms And Conditions' }">
          Terms & Conditions
        </b-link>
      </b-col>
      <b-col class="mx-2">
        <b-link :to="{ name: 'Privacy Policy' }">
          Privacy Policy
        </b-link></b-col
      >
      <b-col class="">
        <b-link :to="{ name: 'Cancellation' }">
          Cancellation and Refund policy
        </b-link></b-col
      >
    </b-row> -->
  </div>
</template>

<script>
import { BLink, BCol, BRow } from "bootstrap-vue";

export default {
  components: {
    BLink,
    BCol,
    BRow,
  },
};
</script>
