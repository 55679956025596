<template>
  <ul>
    <component v-for="(item, ind) in items" :is="resolveNavItemComponent(item)" :key="item.header + ind || item.title" :item="item" />
  </ul>
</template>

<script>
import { provide, ref } from "@vue/composition-api";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";
import VerticalNavMenuGroup from "../vertical-nav-menu-group/VerticalNavMenuGroup.vue";

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    resolveNavItemComponent(item) {

      if (item.header) return "vertical-nav-menu-header";
      if (item.children) return "vertical-nav-menu-group";
      return "vertical-nav-menu-link";
    },
  },
  setup() {
    provide("openGroups", ref([]));

    return {
      // resolveNavItemComponent,
    };
  },
};
</script>
